import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import BlogTemplate from '../components/BlogTemplate'
import Layout from '../components/Layout'
import { removeEmptyProperties } from '../utils/helpers'


const BlogPage = ({ data, pageContext, location }) => {
  const { page, allArticles: posts } = data

  const { meta, cta, search_placeholder } = page.frontmatter

  return (
    <Layout location={location}>
      <Helmet>
        <title>{meta.meta_title}</title>
        <meta name='description' content={meta.meta_description} />
      </Helmet>
      <BlogTemplate posts={posts} pageContext={pageContext} cta={removeEmptyProperties(cta)} searchPlaceholder={search_placeholder} />
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.object,
  }),
}

export default BlogPage

export const query = graphql`
  query blogPosts($skip: Int!, $limit: Int!) {
    page: markdownRemark(frontmatter: { templateKey: { eq: "blog-page" }}) {
      frontmatter {
        meta {
          meta_title
          meta_description
        }
        cta {
          heading
          lead
            button {
              text
              url
            }
        }
        search_placeholder
      }
    }
    allArticles: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "article-page" } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          timeToRead
          excerpt(pruneLength: 119)
          fields {
            slug
          }
          frontmatter {
            title
            cover
            category
            date(formatString: "MMMM DD, YYYY")
            slug
          }
        }
      }
    }
  }
`
